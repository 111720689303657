var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"align-center",attrs:{"id":"app-bar","dense":"","app":"","color":"#fff","height":"60","shrink-on-scroll":"","scroll-target":"#scrolling-techniques-7"}},[_c('v-btn',{staticClass:"mr-3 d-flex d-sm-none",attrs:{"elevation":"1","fab":"","x-small":""},on:{"click":function($event){return _vm.setDrawer(!_vm.drawer)}}},[(_vm.value)?_c('v-icon',[_vm._v("mdi-view-quilt")]):_c('v-icon',[_vm._v("mdi-dots-vertical")])],1),_c('v-btn',{staticClass:"hidden-sm-and-down text-h4 font-weight-bold mr-7",attrs:{"text":"","color":"#167df0"},on:{"click":function () { return this$1.$router.push('/dashboard'); }}},[_vm._v("华创精益")]),_c('v-tabs',{key:_vm.getKey,staticClass:"ml-n5 hidden-sm-and-down",attrs:{"color":"#167df0"}},[_c('v-tab',{key:"0",staticClass:"font-weight-bold",attrs:{"to":{ path: '/dashboard' }}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"color":"#167df0"}},[_vm._v("fa fa-home")]),_vm._v(" 主页 ")],1),_c('v-tab',{key:"1",staticClass:"font-weight-bold",attrs:{"to":{ path: '/BBS' }}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"color":"#167df0"}},[_vm._v("fa fa-user-o")]),_vm._v(" 学习论坛 ")],1),_c('v-tab',{key:"2",staticClass:"font-weight-bold"},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"color":"#167df0"}},[_vm._v("fa fa-line-chart")]),_vm._v(" 点检会 ")],1),_c('v-tab',{key:"3",staticClass:"font-weight-bold"},[_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"color":"#167df0"}},[_vm._v("fa fa-briefcase")]),_vm._v(" 便捷工具 ")],1)]}}])},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item',{staticStyle:{"height":"40px"},attrs:{"link":"","to":{ path: '/LMind' }}},[_vm._v("Lmind")]),_c('v-list-item',{staticStyle:{"height":"40px"},attrs:{"link":"","to":{ path: '/cgformList' }}},[_vm._v("客户周报")]),_c('v-list-item',{staticStyle:{"height":"40px"},attrs:{"link":"","to":{ path: '/qzkbv1' }}},[_vm._v("清障看板v1")]),_c('v-list-item',{staticStyle:{"height":"40px"},attrs:{"link":"","to":{ path: '/obstacle' }}},[_vm._v("清障看板v2")]),_c('v-list-item',{staticStyle:{"height":"40px"},attrs:{"link":"","to":{ path: '/perception' }}},[_vm._v("感知、行动、启发")]),_c('v-list-item',{staticStyle:{"height":"40px"},attrs:{"link":"","to":{ path: '/causeeffect' }}},[_vm._v("因果台账")]),_c('v-list-item',{staticStyle:{"height":"40px"},attrs:{"link":"","to":{ path: '/threebenefit' }}},[_vm._v("精益底层逻辑")]),_c('v-list-item',{staticStyle:{"height":"40px"},attrs:{"link":"","to":{ path: '/five' }}},[_vm._v("5WHAT")]),_c('v-list-item',{staticStyle:{"height":"40px"},attrs:{"link":"","to":{ path: '/fiveElements' }}},[_vm._v("五要素看板")])],1)],1)],1),(_vm.testingList.length > 0)?_c('v-tab',{key:"4",staticClass:"font-weight-bold"},[_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"color":"#167df0"}},[_vm._v("fa fa-line-chart")]),_vm._v(" 考试 ")],1)]}}],null,false,1780505166)},[_c('v-list',{attrs:{"nav":"","dense":""}},[_vm._l((_vm.testingList),function(item,index){return [_c('v-list-item',{key:index,staticStyle:{"height":"40px"},on:{"click":function($event){return _vm.ks(item)}}},[_vm._v(_vm._s(item.menu_name))])]})],2)],1)],1):_vm._e(),_c('v-tab',{key:"5",staticClass:"font-weight-bold"},[_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"color":"#167df0"}},[_vm._v("fa fa-user-o")]),_vm._v(" 作业管理 ")],1)]}}])},[_c('v-list',{attrs:{"nav":"","dense":""}},[(_vm.roleStr.indexOf('gw') > -1)?_c('v-list-item',{staticStyle:{"height":"40px"},attrs:{"link":"","to":{ path: '/homework' }}},[_vm._v("作业发布")]):_vm._e(),_c('v-list-item',{staticStyle:{"height":"40px"},attrs:{"link":"","to":{ path: '/mywork' }}},[_vm._v("我的作业")]),(_vm.roleStr.search(/(gw)|(leader)/) > -1)?_c('v-list-item',{staticStyle:{"height":"40px"},attrs:{"link":"","to":{ path: '/examinework' }}},[_vm._v("作业查看")]):_vm._e()],1)],1)],1),_c('v-tab',{key:"6",staticClass:"font-weight-bold",attrs:{"to":{ path: '/shixunstudy' }}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"color":"#167df0"}},[_vm._v("fa fa-line-chart")]),_vm._v(" 实训学习 ")],1)],1),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"165px"},attrs:{"label":"搜索","color":"secondary","hide-details":""},on:{"keyup":_vm.setT},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":function($event){return _vm.search(_vm.dtext)}}},[_vm._v("mdi-magnify")])]},proxy:true}])}),(_vm.token() == null || _vm.token() === '')?_c('v-btn',{attrs:{"tile":"","small":"","color":"success mx-2","to":{ path: '/login' }}},[_vm._v(" 登录 ")]):_vm._e(),(_vm.token())?_c('DynamicNotice'):_vm._e(),_c('ks-dialogs',{ref:"ksDialogs"}),(_vm.token())?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition","max-width":"300","close-delay":"200","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,1291497099)},[_c('v-list',{attrs:{"tile":"","nav":"","dense":""}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":("" + _vm.baseURL + (_vm.avatar()))}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-subtitle-2 text-truncate"},[_vm._v(_vm._s(_vm.nickname()))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(this.userInfo().email)+" ")])],1)],1),_c('v-divider'),_vm._l((_vm.userSetting),function(item,i){return [_c('v-list-item',{key:i,staticClass:"py-0",attrs:{"link":""},on:{"click":function($event){return _vm.handleClick(item)}}},[_c('v-list-item-action',{staticClass:"mr-1"},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)]})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }