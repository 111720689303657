<template>
  <v-dialog v-model="ksdialogState" width="300"  ref="dialog">
    <v-card class="dialog-content">
      <v-card-title>
        <span class="text-h5">{{ ksmessage }}</span>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="ksdialogState = false"
          >关闭</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    </v-dialog>
</template>
<script>
export default {
  name: 'ksDialogs',
  data() {
    return {
      ksdialogState: false,
      ksmessage: '当前暂未开放考试！'
    }
  }, 
  methods: {
    initShow(msg) {
      this.ksmessage = msg || this.ksmessage
      this.ksdialogState = true
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-content{
  position:absolute; 
  top: 200px;
  width: 300px;
}
</style>
